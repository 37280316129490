<template>
  <div class="legendInfo">
    <InfoItem location="left">
      <div slot="content">
        <div
          class="_content"
          @wheel.stop
          :style="{ height: `${height * 0.75}px` }"
        >
          <div class="title">
            <p class="titleInfo">
              {{ data.title }}找到<span style="color: #ff0000">{{
                data.num
              }}</span
              >宗地块
            </p>
          </div>
          <div
            class="tabbleBox"
            :style="{ height: `${height * 0.75 - 90}px` }"
            @wheel.stop
          >
            <Table
              :loading="dataLoading"
              :border="false"
              :height="height * 0.75 - 90"
              :columns="columns"
              :data="formData"
              @on-sort-change="
                (res) => {
                  getSortValue(res);
                }
              "
            >
              <template slot-scope="{ row }" slot="name">
                <Tooltip
                  :content="row.name"
                  placement="bottom"
                  :max-width="widths"
                  :transfer=true
                >
                  <span
                    @click="gocity(row.lat, row.lng, row.name)"
                    :style="{
                      width: `${widths - 20}px`,
                      color: row.lat == '' ? 'currentcolor' : '#1eaef5',
                      cursor: row.lat == '' ? 'default' : 'pointer',
                      'font-size': '14px',
                    }"
                    class="ivu-table-cell-tooltip-content"
                  >
                    {{ row.name }}
                  </span>
                </Tooltip>
              </template>
            </Table>
          </div>
        </div>
      </div>
    </InfoItem>
  </div>
</template>

<script>
// import InfoItem from './infoItem.vue'
import InfoItem from "../../../components/infoItem.vue";
import { land_data_map_overview_list } from "../../../../../api/reLand.js";
import util from "../../../../../utils/utils2.js";
export default {
  name: "legendInfo",
  components: {
    InfoItem,
  },
  props: {
    //动态设置高度
    height: {
      type: [Number, String],
      default: 700,
    },
    selectedOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    // 地块名称颜色以及点击状态
    // Colors() {
    // 	let colors = ''
    // 	this.formData.forEach(item => {
    // 		colors = item.lat=='' ? 'currentcolor' : '#1eaef5'
    // 	});
    // 	return colors
    // },
    // Cursors() {
    // 	let cursors = ''
    // 	this.formData.forEach(item => {
    // 		cursors = item.lat=='' ? 'default ' : 'pointer'
    // 	});
    // 	return cursors
    // },
  },
  watch: {
    selectedOptions: {
      handler(val) {
        this.searchForm = JSON.parse(JSON.stringify(val));
        this.$nextTick(() => {
          this.getValue();
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      searchForm: {},
      dataLoading: false,
      columns: [
        {
          title: "序号",
          type: "index",
          width: 80,
          align: "center",
        },
        {
          title: "城市",
          key: "name",
          align: "center",
          tooltip: true,
        },
        {
          title: "楼面价(元/m²)",
          sortable: "custom",
          key: "floorPriceSum",
          align: "center",
          render: (h, params) => {
            if (params.row.floorPriceSum == "") {
              return h("span", "--");
            } else {
              return h("span", params.row.floorPriceSum);
            }
          },
        },
        {
          title: "成交价(亿元)",
          sortable: "custom",
          key: "transactionPriceSum",
          align: "center",
          render: (h, params) => {
            if (params.row.transactionPriceSum == "") {
              return h("span", "--");
            } else {
              return h("span", params.row.transactionPriceSum);
            }
          },
        },
      ],
      data: {},
      formData: [],
      timer: null,
      widths: 170,
    };
  },
  methods: {
    gocity(lat, lng, areas) {
      if (lat != "" && lat != "") {
        let position = {
          lat: lat,
          lng: lng,
        };
        this.$emit("goCity", position, 13);
		this.$emit("showComponent", areas);
      }
    },
    getSortValue(res) {
      let { key, order } = res;
      this.getValue(key, order);
    },
    getValue(key, order) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        if (
          this.searchForm.hasOwnProperty("landListAreaObject") &&
          this.searchForm.landListAreaObject.province &&
          this.searchForm.hasOwnProperty("selected") &&
          this.searchForm.hasOwnProperty("showScope")
        ) {
          let obj = this.searchForm;
          let params = {
            orderBy: key ? key : "", //floorPrice
            orderWay: order ? order : "", //normal
          };
          params.dateStart = obj.selected.startTime
            ? util.formatDateFmt(new Date(obj.selected.startTime), "yyyy-MM-dd")
            : "";
          params.dateEnd = obj.selected.endTime
            ? util.formatDateFmt(new Date(obj.selected.endTime), "yyyy-MM-dd")
            : "";
          params.provinceName = obj.landListAreaObject.province;
          params.cityName = obj.landListAreaObject.city;
          params.districtName = obj.landListAreaObject.district;
          params.transactionStateCodeList = obj.selected.type;
          params.zoom = obj.showScope.zoom;
          (params.landUseCodeList =
            obj.selected.useType.length != 0 ? obj.selected.useType : []),
            (params.upperRightPoint = obj.showScope.NorthEast);
          params.lowerLeftPoint = obj.showScope.SouthWest;
          this.columns = this.setOrderBy(
            this.setHeads(params.transactionStateCodeList, params.zoom),
            params.orderBy,
            params.orderWay
          );
          let data = await land_data_map_overview_list(params);
          this.data = data;
          this.formData = this.setValue(data, params.transactionStateCodeList);
        }
      }, 500);
    },
    //设置表格数据
    setValue(obj, typeArr) {
      let hasTransaction = false;
      if (typeArr.length == 1) {
        typeArr.forEach((item) => {
          if (item == 2) {
            hasTransaction = true;
          }
        });
      } else {
        hasTransaction = false;
      }

      if (hasTransaction) {
        return obj.transactionRecordList;
      } else {
        return obj.records;
      }
    },
    //设置表头
    setHeads(typeArr, zoom) {
      let hasTransaction = false;
      if (typeArr.length == 1) {
        typeArr.forEach((item) => {
          if (item == 2) {
            hasTransaction = true;
          }
        });
      } else {
        hasTransaction = false;
      }
      let areaName = "城市";
      let orderWay = "normal";
      //let onewidth = 130
      let twowidth = 160;
      if (zoom < 10) {
        areaName = "城市";
        twowidth = 160;
      } else if (zoom <= 12) {
        if (this.searchForm.landListAreaObject.province == "广东省") {
          areaName = "行政区";
          twowidth = 160;
        } else {
          areaName = "地块名称";
          twowidth = 170;
          this.widths = twowidth;
        }
      } else {
        areaName = "地块名称";
        twowidth = 170;
        this.widths = twowidth;
      }
      //如果是成交，展示楼面价/成交价
      if (hasTransaction) {
        return [
          {
            title: "序号",
            type: "index",
            width: 80,
            align: "center",
          },
          {
            title: areaName,
            key: "name",
            align: "center",
            tooltip: true,
            width: twowidth,
          },
          {
            title: "楼面价(元/m²)",
            sortable: "custom",
            key: "floorPriceSum",
            align: "center",
            sortType: orderWay,
            render: (h, params) => {
              if (params.row.floorPriceSum == "") {
                return h("span", "--");
              } else {
                return h("span", params.row.floorPriceSum);
              }
            },
            //width: onewidth
          },
          {
            title: "成交价(亿元)",
            sortable: "custom",
            key: "transactionPriceSum",
            align: "center",
            sortType: orderWay,
            render: (h, params) => {
              if (params.row.transactionPriceSum == "") {
                return h("span", "--");
              } else {
                return h("span", params.row.transactionPriceSum);
              }
            },
            //width: onewidth
          },
        ];
      } else {
        if (areaName == "地块名称") {
          //地块级别的时候，展示建筑面积/土地面积
          return [
            {
              title: "序号",
              type: "index",
              width: 80,
              align: "center",
            },
            {
              title: areaName,
              key: "name",
              align: "center",
              tooltip: true,
              width: twowidth,
              slot: "name",
            },
            {
              title: "建筑面积(m²)",
              sortable: "custom",
              key: "buildingAreaSum",
              align: "center",
              sortType: orderWay,
              render: (h, params) => {
                if (params.row.buildingAreaSum == "") {
                  return h("span", "--");
                } else {
                  return h("span", params.row.buildingAreaSum);
                }
              },
              //width: onewidth
            },
            {
              title: "土地面积(m²)",
              sortable: "custom",
              key: "landAreaSum",
              align: "center",
              sortType: orderWay,
              render: (h, params) => {
                if (params.row.landAreaSum == "") {
                  return h("span", "--");
                } else {
                  return h("span", params.row.landAreaSum);
                }
              },
              //width: onewidth
            },
          ];
        } else {
          //非地块级别展示宗数/土地面积
          return [
            {
              title: "序号",
              type: "index",
              width: 80,
              align: "center",
            },
            {
              title: areaName,
              key: "name",
              align: "center",
              tooltip: true,
              width: twowidth,
            },
            {
              title: "宗数",
              sortable: "custom",
              key: "number",
              align: "center",
              sortType: orderWay,
              render: (h, params) => {
                if (params.row.number == "") {
                  return h("span", "--");
                } else {
                  return h("span", params.row.number);
                }
              },
              //width: onewidth
            },
            {
              title: "土地面积(万m²)",
              sortable: "custom",
              key: "landAreaSum",
              align: "center",
              sortType: orderWay,
              render: (h, params) => {
                if (params.row.landAreaSum == "") {
                  return h("span", "--");
                } else {
                  return h("span", params.row.landAreaSum);
                }
              },
              //width: onewidth
            },
          ];
        }
      }
    },
    //设置排序
    setOrderBy(heads, orderBy, orderWay) {
      heads.forEach((item) => {
        if (item.key == orderBy) {
          item.sortType = orderWay;
        }
      });
      return heads;
    },
  },
};
</script>

<style scoped lang="scss">
._content {
  height: 700px;
  font-size: 16px;
  padding: 20px;
  overflow-y: auto;
}
/deep/.ivu-table-cell {
  padding-left: 6px;
  padding-right: 6px;
}

.title {
  // border-bottom: 1px solid #0093DB;
  padding: 0 0 0px;

  .titleInfo {
    position: relative;
    font-size: 18px;
    font-family: Source Han Sans CN, Source Han Sans CN-Medium;
    font-weight: 500;
    color: #333333;
    padding-left: 10px;

    &:before {
      content: "";
      width: 6px;
      height: 20px;
      background-color: #0093db;
      position: absolute;
      top: 50%;
      left: -6px;
      transform: translateY(-50%);
    }
  }
}

.tabbleBox {
  margin-top: 10px;
  overflow-y: scroll;
}
</style>
