<template>
	<div class="selectBox">
		<div style='width: 150px;' class="selectItem">
			<CitySelect :ctiy='ctiy' @selectArea='selectArea' ref="CitySelect"></CitySelect>
		</div>
		<div class="selectItem">
			<DatePicker size='large' :options="startOptions" @on-change='selectStartTime' transfer
				v-model="selectForm.startTime" :editable='false' type="date" placeholder="选择起始日">
			</DatePicker>
		</div>
		<div class="selectItem">
			<DatePicker size='large' :options="endOptions" transfer @on-change='sentSelectFrom'
				v-model="selectForm.endTime" :editable='false' type="date" placeholder="选择结束日">
			</DatePicker>
		</div>
		<div class="selectItem" style="width: 180px;">
			<Select size='large' transfer v-model="selectForm.type" @on-change='sentSelectFrom' placeholder='交易状态'
				multiple :max-tag-count='1'>
				<Option :key="_type.code" v-for="_type in typeList" :value="_type.code">{{_type.name}}</Option>
			</Select>
		</div>
		<div class="selectItem" style="width: 180px;">
			<Select size='large' transfer v-model="selectForm.useType" @on-change='sentSelectFrom' placeholder='土地用途'
				multiple :max-tag-count='1'>
				<Option :key="_type.code" v-for="_type in useTypeList" :value="_type.code">{{_type.name}}</Option>
			</Select>
		</div>
	</div>
</template>

<script>
	import CitySelect from '../../../components/citySelect/index.vue'
	export default {
		name: 'selectBox',
		components: {
			CitySelect
		},
		props: {
			ctiy: {
				type: Object,
				default: () => {
					return {}
				},
			},
		},
		watch: {
			ctiy: {
				handler(val) {
					this.selectForm.city = val
				},
				immediate: true,
			}
		},
		data() {
			let _this = this
			return {
				selectForm: {
					city: {},
					startTime: new Date(new Date().valueOf() - 30 * 24 * 60 * 60 * 1000),
					endTime: new Date(),
					type: [0,1,2],
					useType: ['USE_RES', 'USE_COMS', 'USE_IND', 'USE_OTHER'],
				},
				typeList: [{
					name: '已成交',
					code: 2
				}, {
					name: '出让中',
					code: 0
				}, {
					name: '流拍',
					code: 1
				}, ],
				//住宅、商服、工业、其他
				useTypeList: [{
					name: '住宅',
					code: 'USE_RES'
				}, {
					name: '商服',
					code: 'USE_COMS'
				}, {
					name: '工业',
					code: 'USE_IND'
				}, {
					name: '其他',
					code: 'USE_OTHER'
				}, ],
				startOptions: {
					disabledDate(date) {
						return date && date.valueOf() < new Date('2011-1-1') || date.valueOf() > Date.now()
					}
				},
				endOptions: {
					disabledDate(date) {
						//目前的时间戳
						let disavleEndDateValue = Date.now()
						//距离开始时间3年的时间戳
						let threeYears = new Date(_this.selectForm.startTime).valueOf() + 3 * 30 * 24 * 60 * 60 * 1000
						//根据大小，设置禁用的最大值
						if (threeYears < disavleEndDateValue) {
							disavleEndDateValue = threeYears
						}
						return date && date.valueOf() < new Date(_this.selectForm.startTime).valueOf() || date
							.valueOf() >
							disavleEndDateValue
					}
				}
			}
		},
		methods: {
			selectArea(city) {
				this.selectForm.city = city
				this.sentSelectFrom()
				this.$emit('selectedCity', city)
			},
			sentSelectFrom() {
				this.$emit('selected', this.selectForm)
			},
			selectStartTime() {
				this.selectForm.endTime = ''
				this.sentSelectFrom()
			},
			closes() {
				this.$refs.CitySelect.handleClose()
			}
		},
	}
</script>

<style scoped lang="scss">
	.selectBox {
		display: inline-block;
		position: relative;
		font-size: 0;

		.selectItem {
			display: inline-block;
			vertical-align: middle;
			width: 125px;
			margin: 2px;
		}
	}
</style>
