<template>
  <div class="mapSearch" :style="{ height: `${mapHeight}px` }">
    <div class="map-container" ref="modalMap" id="modalMap">
      <baidu-map
        :center="showArea.center"
        :zoom="showArea.zoom"
        @ready="loadComplete"
        :scroll-wheel-zoom="true"
        :max-zoom="19"
        :min-zoom="5"
        @dragend="syncCenter"
        @zoomend="syncZoom"
        @click="close"
      >
        <bm-view class="map"></bm-view>
        <!-- 比例尺 -->
        <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
        <!-- 侧边展示栏 -->
        <bm-control
          anchor="BMAP_ANCHOR_TOP_LEFT"
          :offset="{ width: 0, height: 70 }"
        >
          <Info
            :height="mapHeight"
            :selectedOptions="selectedOptions"
            @goCity="goCity"
            @showComponent="showComponent"
          ></Info>
        </bm-control>
        <!-- 顶部控制器 -->
        <bm-control
          anchor="BMAP_ANCHOR_TOP_LEFT"
          :offset="{ width: 30, height: 30 }"
        >
          <div class="controlBox">
            <MySelect
              :ctiy="showArea"
              @selected="getSelectedForm"
              @selectedCity="getSelectedCity"
              ref="MySelect"
            >
            </MySelect>
            <!-- <Input size="large" :border="false" v-model="query" placeholder="输入地块名称或位置搜索"
							class="inputClass">
						</Input> -->
            <Select
              class="inputClass"
              size="large"
              clearable
              label-in-value
              v-model="selectedLand"
              filterable
              @on-select="selectLand"
              :remote-method="searchLandMethod"
              placeholder="输入地块名称或位置搜索"
              :loading="loading"
            >
              <Option
                v-for="(option, index) in landOptions"
                :value="`${option.landPosition}_${index}`"
                :label="option.landName"
                :key="index"
              >
                {{ option.landName }}
              </Option>
            </Select>
            <Button
              class="ciclebtn"
              shape="circle"
              icon="md-information"
              @click="handleClick"
            ></Button>
            <div v-show="showInfoWindow" class="infoWindow">
              <p class="close" @click.stop="handleClick">X</p>
              <div class="title">数据说明</div>
              <div class="text">
                <p v-for="(item, index) in description" :key="index">
                  {{ item.text }}
                </p>
              </div>
            </div>
          </div>
        </bm-control>

        <!-- 底部选择 -->
        <bm-control
          v-if="showArea.zoom > 12"
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :offset="{ width: 0, height: 0 }"
        >
          <CheckBoxControlforMe
            :group="group"
            :width="mapWidth"
            @setGruop="setGruop"
          >
          </CheckBoxControlforMe>
        </bm-control>

        <!-- 地图打点  -->

        <component
          ref="component"
          :is="componentTag"
          :key="index"
          v-for="(item, index) in circlePath"
          :position="item.center"
          :markObj="item.info"
          :area="item.area"
          :active="item.active"
          :level="item.level"
          :landUseCode="item.landUseCode"
          :zoom="item.zoom"
          :number="item.number"
          @goCity="goCity"
          @handleAddProject="handleAddProject"
          @goLandDetail="goLandDetail"
        >
        </component>
      </baidu-map>
    </div>
  </div>
</template>

<script>
import MySelect from "./components/select.vue";
import Info from "./components/info.vue";
import CheckBoxControl from "../../components/checkBoxControl/index.vue";
import CheckBoxControlforMe from "../../components/CheckBoxControlforMe/index.vue";
import CircleMark from "../../components/circleMark.vue";
import LandMark from "../../components/landMark.vue";
import util from "../../../../utils/utils2.js";
import {
  land_data_land_name_options,
  land_data_position_lat_lng,
  land_data_map_overview,
} from "../../../../api/reLand.js";
import dataReports from "@/mixins/dataReports";

export default {
  name: "mapSearch",
  components: {
    MySelect,
    Info,
    CheckBoxControl,
    CheckBoxControlforMe,
    CircleMark,
    LandMark,
  },
  mixins: [dataReports],
  data() {
    return {
      //远程搜索
      selectedLand: "",
      loading: false,
      landOptions: [],
      timer: null,
      landListAreaObject: {
        streetNumber: "83号",
        street: "教育路",
        district: "越秀区",
        city: "广州市",
        province: "广东省",
        town: "",
      },
      selectedOptions: {},
      componentTag: "CircleMark",
      showArea: {
        center: {
          lat: "35.645501",
          lng: "105.656032",
        },
        city: "全国",
        cityCode: "",
        cityName: "全国",
        isProvinceCapital: "",
        lat: "35.645501",
        level: 0,
        lng: "105.656032",
        zoom: 5,
      },
      // {
      // 	"center": {
      // 		"lat": "23.135336",
      // 		"lng": "113.271431"
      // 	},
      // 	"city": "广州",
      // 	"cityCode": "4401",
      // 	"cityName": "广州市",
      // 	"isProvinceCapital": 1,
      // 	"lat": "23.135336",
      // 	"level": 1,
      // 	"lng": "113.271431",
      // 	"zoom": 13
      // },
      showScope: {
        SouthWest: {
          lng: 113.02996647899766,
          lat: 23.011918008667195,
        },
        NorthEast: {
          lng: 113.51289552100229,
          lat: 23.248279812734918,
        },
        zoom: 13,
      },
      loaded: false,
      showInfoWindow: false,
      description: [
        {
          text: "1、城市范围：当前土地数据涵盖全国一、二线城市共60个城市， 其他城市数据正在建设中。",
        },
        { text: "2、时间范围：土地数据可查询的时间范围为2011-01-01至今。" },
      ],
      map: "",
      BMap: "",
      tools: "",
      mapHeight: document.querySelector("#reLand")
        ? document.querySelector("#reLand").offsetHeight
        : 850,
      mapWidth: document.querySelector("#reLand")
        ? document.querySelector("#reLand").offsetWidth
        : 1640,
      group: ["USE_RES", "USE_COMS", "USE_IND", "USE_OTHER"],
      //地图上展示的点
      circlePath: [],
      //地图上所有的点
      allMapMarks: [],
    };
  },
  created() {
    const _this = this;
    window.onresize = () => {
      return (() => {
        _this.mapHeight = document.querySelector("#reLand").offsetHeight;
        _this.mapWidth = document.querySelector("#reLand").offsetWidth;
      })();
    };
    this.$nextTick(() => {
      this.mapHeight = document.querySelector("#reLand").offsetHeight;
      this.mapWidth = document.querySelector("#reLand").offsetWidth;
    });
  },
  methods: {
    loadComplete({ BMap, map }) {
      this.mapHeight = document.querySelector("#reLand").offsetHeight;
      this.loaded = true;
      this.map = map;
      this.BMap = BMap;
      this.tools = new BMap.Geocoder();
    },
    //获取选择的数据
    getSelectedForm(selected) {
      //当放大等级没有改变是，重置一下放大等级，用以触发缩放方法
      this.$nextTick(() => {
        this.selected = selected;
        this.getMarks();
      });
    },
    //获取选择的城市
    getSelectedCity(city) {
      if (this.showArea.zoom == city.zoom) {
        this.showArea.zoom = 5;
      }
      this.showArea = {
        center: {
          lat: "35.645501",
          lng: "105.656032",
        },
        city: "全国",
        cityCode: "",
        cityName: "全国",
        isProvinceCapital: "",
        lat: "35.645501",
        level: 0,
        lng: "105.656032",
        zoom: 5,
      };
      this.$nextTick(() => {
        this.showArea = city;
      });
    },
    //移动
    syncCenter(e) {
      setTimeout(() => {
        this.getBounds(this.map);
      }, 100);
    },
    //缩放
    syncZoom(e) {
      //缩放时清空所有点
      this.circlePath = [];
      this.showArea.zoom = e.target.getZoom();
      this.showScope.zoom = e.target.getZoom();
      //<8为全国，>=8 && <=11为省份，>11 && <=13为城市，>13以上为行政区，最大为18
      if (this.showArea.zoom <= 12) {
        this.componentTag = "CircleMark";
      } else {
        this.componentTag = "LandMark";
      }
      setTimeout(() => {
        this.getBounds(this.map);
      }, 100);
    },
    getBounds(baidumap) {
      if (baidumap) {
        const Bounds = baidumap.getBounds();
        this.showScope.SouthWest = Bounds.getSouthWest();
        this.showScope.NorthEast = Bounds.getNorthEast();
        let point = Bounds.getCenter();

        //限制地图拖动
        if (
          this.showScope.SouthWest.lat < 0 ||
          this.showScope.SouthWest.lng < 50.843961 ||
          this.showScope.NorthEast.lat > 71.800076 ||
          this.showScope.NorthEast.lng > 173.946797
        ) {
          let position = {
            lat: 35.645501,
            lng: 105.656032,
          };
          this.goCity(position, 5);
        }
        // this.tools.getLocation(point, res => {
        // 	this.landListAreaObject = res.addressComponents
        // 	if (this.showArea.zoom < 8) {
        // 		this.showArea.cityName = '全国'
        // 		this.showArea.city = '全国'
        // 	} else if (this.showArea.zoom >= 8 && this.showArea.zoom <= 12) {
        // 		this.showArea.cityName = res.addressComponents.province ? res.addressComponents
        // 			.province : '全国'
        // 		this.showArea.city = res.addressComponents.province ? res.addressComponents.province :
        // 			'全国'
        // 	} else if (this.showArea.zoom > 12) {
        // 		this.showArea.cityName = res.addressComponents.city ? res.addressComponents.city : '全国'
        // 		this.showArea.city = res.addressComponents.city ? res.addressComponents.city : '全国'
        // 	}
        // 	this.getMarks()
        // })
        this.tools.getLocation(point, (res) => {
          this.landListAreaObject = res.addressComponents;
          if (this.showArea.zoom < 8) {
            this.showArea.cityName = "全国";
            this.showArea.city = "全国";
          } else if (this.showArea.zoom >= 8 && this.showArea.zoom < 10) {
            this.showArea.cityName = res.addressComponents.province
              ? res.addressComponents.province
              : "全国";
            this.showArea.city = res.addressComponents.province
              ? res.addressComponents.province
              : "全国";
          } else if (this.showArea.zoom >= 10 && this.showArea.zoom < 13) {
            this.showArea.cityName = res.addressComponents.city
              ? res.addressComponents.city
              : "全国";
            this.showArea.city = res.addressComponents.city
              ? res.addressComponents.city
              : "全国";
          } else if (this.showArea.zoom >= 13) {
            if (res.addressComponents.province == "广东省") {
              this.showArea.cityName = res.addressComponents.district
                ? res.addressComponents.district
                : res.addressComponents.city;
              this.showArea.city = res.addressComponents.district
                ? res.addressComponents.district
                : res.addressComponents.city;
            } else {
              this.showArea.cityName = res.addressComponents.city
                ? res.addressComponents.city
                : "全国";
              this.showArea.city = res.addressComponents.city
                ? res.addressComponents.city
                : "全国";
            }
          }
          this.getMarks();
        });
      }
    },
    //点击有经纬度的地块，地图上弹出该地块的详情弹框
    showComponent(areas) {
      this.$refs.component.forEach((element) => {
        if (element.areas == areas) {
          element.showComponent();
        }
      });
    },
    //点击跳转
    goCity(position, zoom, detail) {
      this.showArea.center = {
        lat: 0,
        lng: 0,
      };
      this.$nextTick(() => {
        this.showArea.center = position;
        this.showArea.zoom = zoom;
      });
    },
    goLandDetail(e) {
      let { landRelationKey, landPosition, transactionStateCode } = e;
      this.$router.push({
        path: "/Insight/land/landDetail",
        query: {
          landRelationKey: landRelationKey,
          landPosition: landPosition,
          transactionStateCode: transactionStateCode,
        },
      });
    },

    async getMarks() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        this.selected.startTime = this.selected.startTime
          ? util.formatDateFmt(new Date(this.selected.startTime), "yyyy-MM-dd")
          : "";
        this.selected.endTime = this.selected.endTime
          ? util.formatDateFmt(new Date(this.selected.endTime), "yyyy-MM-dd")
          : "";

        this.selectedOptions = {
          selected: this.selected,
          showScope: this.showScope,
          landListAreaObject: this.landListAreaObject,
        };
        let params = {};
        params.cityName = this.landListAreaObject.city
          ? this.landListAreaObject.city
          : "";
        params.landUseCodeList =
          this.selected.useType.length != 0 ? this.selected.useType : [];
        params.dateStart = this.selected.startTime;
        params.dateEnd = this.selected.endTime;
        params.provinceName = this.landListAreaObject.province
          ? this.landListAreaObject.province
          : "";
        params.districtName =
          this.landListAreaObject.district && this.selected.city.zoom < 13
            ? this.landListAreaObject.district
            : "";
        params.transactionStateCodeList = this.selected.type;
        params.zoom = this.selected.city.zoom;
        params.lowerLeftPoint = this.showScope.SouthWest;
        params.upperRightPoint = this.showScope.NorthEast;
        let data = await land_data_map_overview(params);
        let marksList = data.mapPunctuations;
        let ARR = [];
        marksList.forEach((item) => {
          let obj = {};
          obj.info = item.detail;
          obj.area = item.pointName;
          obj.center = {
            lat: item.lat,
            lng: item.lng,
          };
          obj.level = item.level;
          obj.number = item.number;
          obj.zoom = item.zoom;
          obj.active = false;
          obj.landUseCode = item.detail.landUseCode;
          ARR.push(obj);
        });
        this.allMapMarks = ARR;
        this.circlePath = this.allMapMarks;
        //this.group = this.getAllGroup(this.allMapMarks)
        this.getShowType(this.group);
      }, 500);
    },
    //获取所有的土地用途
    getAllGroup(arr) {
      let landUseCodeList = arr.reduce((pre, cur) => {
        if (!pre.includes(cur.landUseCode)) {
          pre.push(cur.landUseCode);
        }
        return pre;
      }, []);
      return landUseCodeList;
    },
    //获取展示的点
    getShowType(e) {
      if (e.length != 0) {
        let arr = [];
        e.forEach((item) => {
          arr = arr.concat(
            this.allMapMarks.filter((mark) => {
              return mark.landUseCode == item;
            })
          );
        });
        this.circlePath = arr;
      } else {
        this.circlePath = this.allMapMarks;
      }
    },
    setGruop(arr) {
      this.$refs.component.forEach((element) => {
        element.hideSelect();
      });
      this.group = [];
      arr.forEach((item) => {
        if (item.checked) {
          this.group.push(item.label);
        }
      });
      this.getShowType(this.group);
    },
    //远程搜索下拉
    async searchLandMethod(q) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        this.getBounds(this.map);
        let params = {};
        params.landName = q;
        params.provinceName = this.landListAreaObject.province
          ? this.landListAreaObject.province
          : "";
        params.cityName = this.landListAreaObject.city
          ? this.landListAreaObject.city
          : "";
        params.districtName = this.landListAreaObject.district
          ? this.landListAreaObject.district
          : "";
        params.dateStart = this.selected.startTime
          ? util.formatDateFmt(new Date(this.selected.startTime), "yyyy-MM-dd")
          : "";
        params.dateEnd = this.selected.endTime
          ? util.formatDateFmt(new Date(this.selected.endTime), "yyyy-MM-dd")
          : "";
        params.landUseCodeList =
          this.selected.useType.length != 0 ? this.selected.useType : [];
        (params.transactionStateCodeList = this.selected.type
          ? this.selected.type
          : []),
          (params.zoom = this.selected.city.zoom);
        let data = await land_data_land_name_options(params);
        this.landOptions = data;
      }, 500);
    },
    //选择下拉
    async selectLand(e) {
      //重置一下地图的缩放等级
      let zoom = this.showArea.zoom;
      let landPosition = e.value.split("_")[0];
      this.$nextTick(async () => {
        let data = await land_data_position_lat_lng({
          landPosition: landPosition,
        });
        let { lat, lng } = data;
        if (lat && lng) {
          this.showArea = {
            center: {
              lat: "35.645501",
              lng: "105.656032",
            },
            city: "全国",
            cityCode: "",
            cityName: "全国",
            isProvinceCapital: "",
            lat: "35.645501",
            level: 0,
            lng: "105.656032",
            zoom: 5,
          };
          this.$nextTick(() => {
            this.showArea.zoom = 15;
            this.showArea.center = {
              lat: lat,
              lng: lng,
            };
            this.landOptions = [];
            this.landName = "";
          });
        } else {
          this.showArea.zoom = zoom;
          this.$msg.error({
            text: "该地块暂无位置信息，请在“地块列表”搜索查看",
          });
        }
      });
    },
    //看项目
    handleAddProject(e) {

      // this.showAddProjectModal = true
      // this.addProjectId = e.id;
      // this.address.lng = e.address.lng;
      // this.address.lat = e.address.lat;
      // this.projectName = e.projectName
    },
    //数据说明提示框
    handleClick() {
      this.showInfoWindow = !this.showInfoWindow;
    },
    close() {
      this.showInfoWindow = false;
      this.$refs.MySelect.closes();
    },
  },
};
</script>

<style scoped lang="scss">
.mapSearch {
  width: 100%;
}

.map-container {
  height: 100%;
  overflow: hidden;

  .map {
    width: 100%;
    height: 100%;
  }
}

.map-container > div {
  width: 100%;
  height: 100%;
}

.controlBox {
  font-size: 0;

  .inputClass {
    width: 300px;
    font-size: 14px;
    margin-left: 5px;
  }

  .ciclebtn {
    margin: 5px;
  }
  .infoWindow {
    position: absolute;
    width: 430px;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    top: 60px;
    left: 96%;
    z-index: 999;
    font-size: 14px;
    padding: 16px;
    text-align: left;
    border-radius: 8px;
    .title {
      text-align: center;
      font-size: 16px;
      font-family: Source Han Sans CN, Source Han Sans CN-Medium;
      font-weight: 500;
      color: #999999;
      line-height: 20px;
    }
    .text {
      margin: 10px 4px;
      font-size: 14px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      line-height: 20px;

      p{
            margin: 10px 0;
          }
    }
  }
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #333333;
  cursor: pointer;
  font-size: 14px;
}
</style>
